import React, { useEffect, useState, Fragment } from 'react'
import {Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

const Settings = (props) => {
	const [libraryList, setLibraryList ] = useState()
	const [linkData, setLinkData ] = useState()
	
	useEffect(() => {
		
		props.ReturnJsonDataFromServer(
			"https://lirp.fi/api/v1/locations/public/juuri",
			"",
			setLibraryList
			)
			
			if( typeof( props.personalLinks ) != "object")
			setLinkData( JSON.parse( props.personalLinks ) )
			
		},[])
		
		useEffect(() => {
			
			if( typeof( props.personalLinks ) != "object")
			setLinkData( JSON.parse( props.personalLinks ) )
			
			
		}, [props.personalLinks, props.cookieConsent])
		
		useEffect(() => {
		}, [linkData])
		
		return (
			<aside className = {props.settingsOpen ? "" : "active"}>
			<h3> Ohjeet </h3>
			<div className="row">
			<p> Voit etsiä linkkejä käyttäen hakukenttää. Hae linkkiä joko palvelun nimellä tai asiasanalla joka liittyy palveluun (esim. Princh Adminin löytää termillä "tulostus" </p>
			<div className="column">
			
			{( libraryList && Object.keys( libraryList ).length > 0) ? (
				<Fragment>
				<p> Valitse kirjastosi. Tämä vaikuttaa siihen mitä linkkejä näet </p>
				<select className="library" value = {props.selectedLibrary} onChange = {event =>
					props.selectedLibraryCallback(props.defaultLibrary, event.target.value, props.setSelectedLibrary)
				}>
				{
					Object.keys(libraryList).map((key) => {
						return <option key = { "library"+key } value={ key } > { key + " - " + libraryList[key] } </option>
					})
				}
				</select>
				</Fragment>
				) : ""}
				
				<div className="cookieRedo">
				<p> Haluan muuttaa mielipidettäni evästeistä. Oheinen nappi poistaa kaikki aiemmat evästeet ja lataa sivun uudestaan.</p>
				<button onClick = {() => {
					sessionStorage.clear()
					resetCookieConsentValue()
					Cookies.remove("library")
					Cookies.remove("personalLinks")
					window.location.reload();
				}
				
			}>Tyhjennä evästeet</button>
			</div>
			</div>
			</div>
			
			<div className="column personal">
			{ (getCookieConsentValue() != "true" ) ? (
				<div className="overlay">
				<h2>Tämä ominaisuus edellyttää evästeiden hyväksyntää</h2>
				</div>
				) : ""
			}
			
			<h3> Henkilökohtaiset linkit </h3>
			<p> Henkilökohtaiset linkit talletetaan selaimesi muistiin evästeinä. Arkaluontoisia henkilökohtaisia linkkejä tulisi käyttää vain työasemilla tai omilla laitteilla. Muutokset tallentuvat automaattisesti </p>
			
			<form className="row"
			onSubmit={event => {event.preventDefault(); props.personalLinksCallback(event.target.name.value, event.target.url.value, null, props.setPersonalLinks)}}
			onReset={event => {
				event.target.querySelector("input").focus();
				props.callback("")
			}}>
			<input required name="name" type="text" placeholder="NIMI"/>
			<input required name="url" type="url" defaultValue="https://" pattern="http(s|)://.*" placeholder="LINKKI"/>
			<input type="submit" value="LISÄÄ LINKKI"/>
			</form>
			
			<ul>{
				
				linkData && Object.keys(linkData)
				.map(
					index => {
						
						var personalLinkData = JSON.parse(linkData[index])
						return(
							<li >
							<i> Nimi </i>
							<input type="text" defaultValue = { personalLinkData.name } onChange = {event => {
								props.personalLinksCallback(event.target.value, null, index, props.setPersonalLinks)
							}}
							/>
							<i> Linkki </i>
							<input type="url" defaultValue = { personalLinkData.url }  onChange = {event => {
								props.personalLinksCallback(null, event.target.value, index, props.setPersonalLinks)
							}}/>
							<button onClick = { () => {
								props.personalLinksCallback("", "", -index, props.setPersonalLinks)
							}}>Poista</button>
							</li>
							)
						})
						
					}
					</ul>
					</div>
					</aside>
					);
				}
				export default Settings
