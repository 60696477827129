import { Cookies, getCookieConsentValue } from "react-cookie-consent";

const headers = { 
    'Content-Type': 'application/json',
    'Accept': 'application/json'
}

export function Get_Json_Data_From_Server(baseurl, variable = "", sethook) {
    
    fetch(baseurl + variable, headers)
    
    .then((response) => response.json())
    
    .then((responseJSON) => {
        
        sethook(responseJSON)
        return responseJSON
        
    })
}



export function storeSelectedLibrary(defaultLibrary, selectedLibrary, setSelectedLibrary) {
    if(selectedLibrary == null) {
        if(sessionStorage["library"] == undefined) sessionStorage["library"] = defaultLibrary
        setSelectedLibrary(sessionStorage["library"] ?? defaultLibrary)
        
        if( getCookieConsentValue() != "true" ) return sessionStorage["library"] ?? defaultLibrary
        
        if( Cookies.get("library") == null ) return sessionStorage["library"] ?? defaultLibrary
        
        setSelectedLibrary(Cookies.get("library"))
        createCookie("library", Cookies.get("library"))
        return Cookies.get("library")
        
    }
    
    setSelectedLibrary(selectedLibrary)
    
    if(getCookieConsentValue() == "true") {
        console.log("Creating Cookie")
        createCookie("library", selectedLibrary)
    } else {
        console.log("Cannot create cookie")
        sessionStorage["library"] = selectedLibrary
    }  
    return selectedLibrary
    
}

export function createCookie(name, value) {
    let cookieOptions = { expires: 30, httponly: false};
    Cookies.set(name, value, cookieOptions)
}

export function setCookieConsentTo(consentValue, setCookiesAllowed) {
    setCookiesAllowed(consentValue)
}

export function managePersonalLinks( name, url, id = "new", setPersonalLinks ) {
    
    if (getCookieConsentValue() != "true") return "";
    if (id == null) id = "new";
    
    var personalLinks = Cookies.get("personalLinks") ?? "{}"
    console.log(name, url, id)
    console.log(personalLinks)
    
    personalLinks = JSON.parse(personalLinks)
    console.log(personalLinks)
    if(id == "new") {
      id = (parseInt(
        Object.keys(personalLinks)
        .sort(function(a, b) {
          return a - b;
        })
        .pop()
      ) || 0 )
          
      var temp = {"name":name,"url":url}
      personalLinks[id+1] = JSON.stringify(temp)        
      console.log(id)
    }
      
    if(id > 0) { //editing
      var temp = JSON.parse(personalLinks[id])
      temp.name = name ?? temp.name;
      temp.url = url ?? temp.url;
      personalLinks[id] = JSON.stringify(temp)
    }
    
    if(id < 0) { //removing
      id = Math.abs(id)
      delete personalLinks[id]
    }
    
    console.log(JSON.stringify(personalLinks))
    createCookie("personalLinks", JSON.stringify(personalLinks))
    setPersonalLinks(JSON.stringify(personalLinks))
  }
    
    export function ReturnLinkCategory(linkCategory = {}) {
        
        var links = linkCategory.links
        
        
        if(linkCategory.children && linkCategory.children.length > 0) {
            linkCategory.children.forEach((child => 
                links = links.concat(
                    ReturnLinkCategory(child)
                    )
                    ))
                }
                
                links["name"] = linkCategory["name"] ?? null
                links["description"] = linkCategory["description"] ?? null
                links["keywords"] = linkCategory["keywords"] ?? null
                
                return links
                
            }