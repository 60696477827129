import React, { useEffect, useState } from 'react'
import { FaArrowUp } from 'react-icons/fa';
const ScrollUpArrow = () => {
    const [isVisible, setIsVisible] = useState(false);


    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      
      const toggleVisibility = () => {
        if (window.pageYOffset > 50) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
  
  
    return (
    
            <div className={isVisible ? "scroll-to-top" : "transparent scroll-to-top" }>
                <div onClick={scrollToTop}>
                    < FaArrowUp />
              </div>    
            </div>
    );
  }

  export default ScrollUpArrow;