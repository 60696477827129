import React from 'react'

const Link = (props) => {
  return(
    <li>
      <a target="_blank" rel="noreferrer noopener" href = { props.url === "https://resistance.fi" ? "https://enlightened.fi/" : props.url } > { props.name }</a> 
    </li>
  )
}

export default Link
