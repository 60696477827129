import React from 'react';
import Link from './Link'
const Link_Category = (props) => {

    return (
            props.links.map(link => {
                    
                    return <Link key = { link.name } name = { link.name } keywords = { link.keywords } url = { link.url }  />  
            })
            
    )
}

export default Link_Category