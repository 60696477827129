import React, { Fragment } from 'react'

const Settings_Gear = (props) => {
    return (
        <Fragment>
            
            <button htmlFor="admin" id="admin__gear" title="Admin mode" className="admin__gear" onClick = {event => props.callback()}>	
				<svg role="img" aria-label="Admin mode">
					<use xlinkHref="#sbx-icon-gear"></use>
				</svg>
			</button>
        </Fragment>
    )
}
export default Settings_Gear