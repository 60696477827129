import React, { useState, useEffect, Fragment } from 'react';

import Input from './Input';
import './style/style.css';
import Link_Category from './Link_Category';
import Settings from './Settings';
import Settings_Gear from './Settings_Gear';
import ScrollUpArrow from './ScrollUpArrow';
import CookieConsent, { Cookies, resetCookieConsentValue  } from "react-cookie-consent";

import {  Get_Json_Data_From_Server,
  storeSelectedLibrary,
  setCookieConsentTo,
  managePersonalLinks,
  ReturnLinkCategory
} from './Helpers';

import { FaHeart } from 'react-icons/fa';
  /*

  TO DO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  
  Copy newer style from public build. Replace some parts here and there to fit the new logo image setup. 

  */
/* READ THIS

To the unfortunate soul who has been tasked with fixing this, here's a general rundown.

This software has a few components:
  App (root) - In charge of everything? All functions pass through App to Helpers.js or get processed here
  Helpers - has some function cause App.js was getting kinda big

  Settings and Settings_gear - Settings_gear acts as a toggle switch to Settings. Settings calls a lot of functions, from App and Helpers, so there's a few callbacks.

  Input - Gets the input and passes it to App

  Link_Category and Link - Handles the display of links. Nothing fancy.

The software works as follows:
  1. Get cookies, set library from cookies, session storage or default (descending priority)
  2. Get all links from that librarys LIRP database. 
  3. Get personal links if cookies are allowed
  4. Combine personal links with the lirp links to create a new array
  5. Filter that new array with whatever is in input.
  6. Display links.

React has neat hook/rendering system, which I'm sure you can grasp by just looking at useEffects. Basically whenever a hook 
(that is, inputValue, selectedLibrary) useEffect runs. So when a customer selects a new library, it reruns the lirp data script
which in turn combines the personal links and lirp links which in turn re-filters the links and updates it visually.
But if a client just updates the input query value, then only the filtering script gets rerun which renders some parts of app.js

Cookies contain selected library and personal links, both in string format although personal links get parsed to json. If you get a cross-reference error, it means that the cookies are being stores and recieved as objects which is bad.

There are TWO API URLS. One on App.js, the other on Settings.js. Change to according version.

This site works in accordance with Traficom's Cookie policy.
*/


function App() {
  const defaultLibrary = "e23"
  
  const [LirpLinksJsonData, setLirpLinksJsonData] = useState()
  const [combinedLinks, setCombinedLinks] = useState()
  const [filteredLinks, setFilteredLinks] = useState([])
  
  const [inputValue, setInputValue] = useState("")
  const [settingsOpenBoolean, setSettingsOpenBoolean] = useState(false)
  const [selectedLibrary, setSelectedLibrary] = useState(Cookies.get("library") || defaultLibrary)
  const [personalLinks, setPersonalLinks] = useState([])
  const [cookiesAllowed, setCookiesAllowed] = useState()
  
  
  const Get_Selected_Library = () => {
    return selectedLibrary
  }
  
  const toggleSettingsOpen = () => {
    setSettingsOpenBoolean(!settingsOpenBoolean)
  }
  
  const combinePersonalLinksAndLirpLinks = (data = LirpLinksJsonData) => {
    
    if ( !data || data.length <= 0 ) return LirpLinksJsonData
    if ( typeof(personalLinks)  == "object" ) return data
    const name = "Omat linkit"
    const description = "Täältä löytyy asetuksista asettamasi linkit"
    
    
    
    const links = Object.keys(JSON.parse(personalLinks)).map(index => {
      return JSON.parse(JSON.parse(personalLinks)[index])
    })
    
    const personalLinkArray = {}
    personalLinkArray.links = links
    personalLinkArray.name=name
    personalLinkArray.description=description
    personalLinkArray.keywords=[]

    const temp = [personalLinkArray].concat(data)
    return temp
    
  }
  
  const GetFilteredLinks = (combinedLinks) => {
    if(!combinedLinks) { 
      return 0;
    }
    const links = combinedLinks.map(link_group => {
      return ReturnLinkCategory(link_group)
    })
    
    return ( links.map(link_group => {
      
      if( ! link_group ) { return "" }
      
      var filtered_link_group = link_group.filter(link => {
        return (link.keywords ?? [])
        .join(" ")
        .toString()
        .toLowerCase()
        .includes(inputValue
          .toLowerCase()
        )
        ||
        (link.name ?? "")
        .toString()
        .toLowerCase()
        .includes(inputValue
          .toLowerCase()
        )
        ||
        (link_group.name ?? "")
        .toString()
        .toLowerCase()
        .includes(inputValue
          .toLowerCase()
        )
        ||
        (link_group.keywords ?? "")
        .toString()
        .toLowerCase()
        .includes(inputValue
          .toLowerCase()
        )      
      })
          
      filtered_link_group["name"] = link_group["name"] ?? null
      filtered_link_group["description"] = link_group["description"] ?? null
      
      return filtered_link_group
    }))
  }
      
    
    
  useEffect(() => {
    Get_Json_Data_From_Server(
      "https://lirp.fi/api/v1/links/public/",
      Get_Selected_Library(),
      setLirpLinksJsonData
      )
  }, [selectedLibrary])
      
  useEffect(() => {
    setCombinedLinks(
      combinePersonalLinksAndLirpLinks(LirpLinksJsonData)
    )
  }, [LirpLinksJsonData, personalLinks])
    
  useEffect(() => {
    setFilteredLinks(
      GetFilteredLinks(combinedLinks)
    )
  }, [combinedLinks, inputValue])
    
  useEffect(() => {
    setPersonalLinks(Cookies.get("personalLinks") ?? {})
  }, [cookiesAllowed])
    
    
    
  return (
    <div className="app">
    
    <CookieConsent
    enableDeclineButton
    
    onAccept = {() => { setCookieConsentTo(true, setCookiesAllowed) }}
    declineButtonText = "Vain pakolliset"
    buttonText = "Hyväksy kaikki"
    
    disableStyles={true}
    containerClasses="cookie-alert-container"
    contentClasses="cookie-content"
    buttonWrapperClasses="cookie-button-wrapper"
    buttonClasses="cookie-button accept-button"
    declineButtonClasses="cookie-button decline-button"
    
    
    
    
    >
      <svg role="img" aria-label="Cookie">
        <use xlinkHref="#sbx-icon-c2"></use>
	      </svg>
      <h3>Evästekäytäntö</h3>
      
      <p>Käytämme evästeitä muistamaan mukautetut asetukset ja henkilökohtaiset linkit myös käyttösession jälkeen.</p>
    </CookieConsent>
    
    <header>
      
      <h1>Juuri</h1>
      <h2>Espoon kirjastojen linkkitietokanta</h2>

    <Settings_Gear callback = { toggleSettingsOpen}/>
    <ScrollUpArrow />
    </header>
    
    <Settings settingsOpen = { settingsOpenBoolean }
    selectedLibraryCallback = { storeSelectedLibrary }
    selectedLibrary = { selectedLibrary }
    setSelectedLibrary = { setSelectedLibrary } //Hook
    defaultLibrary = { defaultLibrary }
    personalLinksCallback = { managePersonalLinks }
    setPersonalLinks = { setPersonalLinks } //Hook
    personalLinks = { personalLinks }
    ReturnJsonDataFromServer = { Get_Json_Data_From_Server }
    cookieHook = { cookiesAllowed }
    />
      
    <main>
    <Input callback = { setInputValue } value = { inputValue }/>
    </main>
    <content>
      
    { 
      filteredLinks.length > 0 && filteredLinks.some(linkList => linkList.length > 0) ? (
        filteredLinks.map((links_category, index) => {
          return <article key = { "linkMainCategory" + index}>
          {
            (filteredLinks[index] ?? new Array(2) ).length > 0 ? (
              
              <Fragment>
                <h2 onClick = { event => {
                  setInputValue(links_category.name)
                  }
                }> { links_category.name } </h2>

                <h3> { links_category.description } </h3>
              </Fragment>
            ) : ("")
          }
          
          <Link_Category links = { links_category } />
          
          </article>
        })) : (
          <h4>Hakusanalla ei löytynyt linkkejä :( <br/> Kokeile jotain muuta?</h4>
            )
          }
          </content>
          <footer>
              <img className="logo w20" alt="Juuri logo" loading="lazy" width="512px" height="512px" src={process.env.PUBLIC_URL + '/android-chrome-512x512.png'} />
              <div className="column w40">
                <h2>Juuri</h2>
                <h5>with <FaHeart /> by Joonas Tynjälä</h5>
              </div>
              <div className="column w40">
                <a onClick = {() => {
                  sessionStorage.clear()
                  resetCookieConsentValue()
                  Cookies.remove("library")
                  Cookies.remove("personalLinks")
                  window.location.reload();
                }}>Tyhjennä evästeet</a>
                <a onClick = {() => {
                  toggleSettingsOpen()
                }}>Avaa asetukset</a>
                <a href="mailto:juunas144@gmail.com">Anna palautetta</a>
                <a href="mailto:onni.rantanen@espoo.fi">Lähetä linkkiehdotuksia</a>
              </div>

            </footer>
          </div>
          
          );
        }
        
        export default App;
        
