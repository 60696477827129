import React from 'react';


const Input = (props) => {
    
    return(
        <form noValidate="novalidate" id="form" className="searchbox sbx-custom"
        onSubmit={event => event.preventDefault()}
        onReset={event => {
            event.target.querySelector("input").focus();
            props.callback("")
            }
        } >
            <div role="search" className="sbx-custom__wrapper">
                <input value= { props.value } type="text" id="search" name="search" placeholder="Hae linkkejä tästä!" autoComplete="off" required="required" className="sbx-custom__input"
                    onChange={event => props.callback(event.target.value
                        .toString()
                    )}
                ></input>
                <button type="button" title="Hae linkkiä aihesanalla." className="sbx-custom__submit sbx-custom__button">
                    <svg role="img" aria-label="Search">
                        <use xlinkHref="#sbx-icon-search-18"></use>
                    </svg>
                </button>
                <button type="reset" title="Tyhjennä hakukenttä" className="sbx-custom__reset sbx-custom__button" >
                    <svg className="" role="img" aria-label="Reset">
                        <use className="" xlinkHref="#sbx-icon-clear-5"></use>
                    </svg>
                </button>
            </div>
        </form>
    );
}

export default Input